/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "CitrusGothic";
  /* src: url("./assets/fonts/citrus_gothic/CitrusGothic-Regular.otf"); */
  src: url("./assets/fonts/CitrusGothic1.ttf");
  font-size: 18;
  letter-spacing: 0.5px;
  font-weight: 100 !important;
}
@font-face {
  font-family: "Thunder";
  src: url("./assets/fonts/thunder_nova/thundernova3d.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "Sora";
  /* src: url("./assets/fonts/citrus_gothic/CitrusGothic-Regular.otf"); */
  src: url("./assets/fonts/Sora/Sora-VariableFont_wght.ttf");
  /* font-size: 18; */
  /* letter-spacing: 0.5px; */
  /* font-weight: 100 !important; */
}

body {
  margin: 0 !important;
  padding: 0 !important;
  font-family: "CitrusGothic";
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
}

.MuiTypography-root,
.MuiInputLabel-root,
.MuiMenuItem-root {
  font-family: "CitrusGothic" !important;
  letter-spacing: 0.5px !important;
  font-style: normal !important;
}
