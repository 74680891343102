.image-container {
  width: 100%;
}

.image {
  height: 70px;
  width: 90px;
  cursor: pointer;
}

.website-image-container {
  width: 100px;
}

.website-image {
  height: 60px;
  width: 150px;
  cursor: pointer;
  padding-top: 30px;
}
