.deals-container {
  position: relative;
  width: 100%;
  height: 28em;
  overflow: hidden;
}

.imageTopLeft {
  position: absolute;
  top: 0;
  left: 0;
  width: 15%;
  height: 30%;
}

.imageTopRight {
  position: absolute;
  top: 0;
  right: 0;
  width: 15%;
  height: 30%;
}

.title {
  position: absolute;
  left: 15%;
  right: 15%;
  top: 11%;
  display: flex;
  justify-content: center;
}

.slideshowContainer {
  position: absolute;
  top: 23%;
  left: 5%;
  right: 5%;
  height: 18em;
}

.slide {
  max-width: 100%;
  max-height: 100%;
}

.deals-carousel-container {
  position: relative;
  width: 100%;
  height: inherit;
  border-radius: 15px;
  /* border: "0.3em solid #f9f4ea"; */
  /* Set the desired height */
}

.deals-carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
}

.text-top-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;
  top: 5%;
  left: 5%;
}

.text-bottom-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;
  bottom: 10%;
  left: 5%;
}

.top-text {
  font-size: 24px;
}

.bottom-text {
  font-size: 18px;
}

.circle-container {
  position: absolute;
  bottom: -2.5em;
  /* Adjust the value to position the circle */
  right: 10px;
  /* Adjust the value to position the circle */
}

.circle {
  width: 5em;
  height: 5em;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* deals.css */
.note-section {
  background-color: #212121; /* Dark background color */
  color: white; /* White text */
  padding: 1rem 0; /* Vertical padding */
  text-align: center; /* Center the text */
}


