.list-item-container > span {
  font-size: 1.2em !important;
}

.css-kd6ijm-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: #EC643C !important;
} 

/* Common save and cancel button styles */
.save-button {
  padding: 0.5em !important;
  color: white !important;
  background-color: #ec643c !important;
  font-size: 1.1em !important;
  font-weight: 500 !important;
  letter-spacing: 0.1em !important;
}

.cancel-button {
  padding: 0.5em !important;
  color: #000 !important;
  background-color: #fff !important;
  font-size: 1.1em !important;
  font-weight: 500 !important;
  letter-spacing: 0.1em !important;
  border: 1px solid #000 !important;
}

.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
  padding: 0 !important; 
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  box-shadow: none !important;
}

.css-15v22id-MuiAccordionDetails-root {
  padding: 0 !important;
}

.elipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


.mainContainer {
  position: relative;
  width: 100%;
  height: 18em;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.order-now-image {
  height: 2.8rem;
  width: 12rem;
  margin-top: 1.5em !important;
}

.order-now-image img {
  height: inherit;
  width: inherit;
}

.mainContainer .bannerContent {
  position: absolute;
  bottom: 15%;
  /* Position content 20% from bottom of container */
  left: 7%;
  /* Position content 5% from left of container */
  color: #fff;
  width: 30%;
}

.bannerButton {
  padding: 1em;
  color: white !important;
  background-color: #ec643c !important;
  font-weight: lighter;
  margin-top: 1em !important;
}

/* .background-image {
  background-image: url("../../assets/images/background1.jpg");
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0;
  margin-top: -150px;
  position: relative;
} */
.background-image {
  background-image: url("../src/assets/images/background1.jpg"); 
  background-size: cover;
  background-position: center;
  display: grid;
  align-items: center;
  justify-content: center;
  padding-top: 0;
  width: 100%;
  height: 103vh;
  margin-top: -150px;
}
.halal-certification {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 20px; 
  margin-bottom: 50px;
}

.halal-certification img {
  height: 100px; 
  width: auto;
}

@media (max-width: 1024px) {
  .background-image {
    height: 93vh;
  }
}

@media (max-width: 768px) {
  .background-image {
    height: 88vh;
  }
}

@media (max-width: 480px) {
  .background-image {
    height: 82vh;
  }
}

@media (max-width: 1024px) {
  .halal-certification img{
    height: 100px;
  }
}

@media (max-width: 768px) {
  .halal-certification img{
    height: 80px;
  }
}

@media (max-width: 480px) {
  .halal-certification img{
    height: 70px;
  }
}
@media (max-width: 1024px) {
  .halal-certification {
    margin: 20px; 
    margin-bottom: 50px;
  }
}

@media (max-width: 768px) {
  .halal-certification {
    margin: 20px; 
    margin-bottom: 50px;
  }
}

@media (max-width: 480px) {
  .halal-certification {
    margin: 10px; 
    margin-bottom: 26px;
  }
}


.background-image2 {
  background-image: url("../src/assets/images/Rectangle.png");
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-top: 0;
  padding-bottom: 0;
  position: relative; /* Important to allow pseudo-elements to be positioned */
}

.background-image2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../src/assets/images/new-deal.png");
  background-size: cover; /* Ensures the new image covers the entire area */
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1; /* Ensures it appears on top */
}

@media (max-width: 1024px) {
  .background-image2 {
    height: 80vh;
  }

  .background-image2::before {
    height: 80vh;
  }
}

@media (max-width: 768px) {
  .background-image2 {
    height: 50vh;
  }

  .background-image2::before {
    height: 50vh;
  }
}

@media (max-width: 480px) {
  .background-image2 {
    height: 36vh;
  }

  .background-image2::before {
    height: 36vh;
  }
}


@media (min-width: 768px) {
  .mainContainer .bannerContent {
    position: absolute;
    bottom: 20%;
    left: 25%;
    width: 20%;
  }
}

@media (min-width: 1366px) {
  .mainContainer .bannerContent {
    position: absolute;
    bottom: 30%;
    
    left: 20%;
    
    width: 40%;
  }
}

/* Scrolling css Starts */

.scrolling-background {
  position: relative;
  width: 100%;
  height: inherit;
 
  overflow: hidden;
  touch-action: pan-y;
  background-color: rgba(28, 20, 20, 0.31);
  
}

.scrolling-background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 100%;
  opacity: 0.9;
  background-image: url("../src/assets/images/banner_main.JPG");
  /* Replace with your image URL */
  background-size: auto 100%;
  animation: scrollBackground 10s linear infinite alternate;
  /* Add 'alternate' to reverse the animation */
  animation-play-state: running;
}

/* Add this to your banner.css or relevant CSS file */
.horizontal-scroll {
  display: flex;
  white-space: nowrap;
  overflow: hidden; /* Hide the overflowed text */
}

.scroll-content {
  display: inline-block;
  padding-left: 100%; /* Ensures a smooth continuous flow */
  animation: scroll 40s linear infinite; /* Continuous scrolling animation */
}

@keyframes scroll {
  0% {
    transform: translateX(0); /* Start at the original position */
  }
  100% {
    transform: translateX(-100%); /* Move completely off-screen */
  }
}

.fog-effect {
  background: linear-gradient(
    to top,
    rgba(253, 207, 114, 50),
    rgba(255, 255, 255, 0) 100%
  );
  pointer-events: none; 
}

