/* FAQ Background Styling */
.new-deal-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; 
  height: 100%; 
  object-fit: cover; 
  z-index: 10;
  pointer-events: none; /* Ensure the image doesn't block other elements */

}
  
  /* Ensuring the fog effect remains on top of the image */
  .fog-effect {
    background: linear-gradient(
      to top,
      rgba(253, 207, 114, 0.7),
      rgba(255, 255, 255, 0) 100%
    );
    pointer-events: none; /* Ensure it doesn't interfere with other elements */
  }
  
  @media (max-width: 1024px) {
    .faq-background {
      height: auto;
    }
  }
  
  @media (max-width: 768px) {
    .faq-background {
      height: auto;
    }
  }
  
  @media (max-width: 480px) {
    .faq-background {
      height: auto;
    }
  }
  