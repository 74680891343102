.appBar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.toolbar {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.cartBadge {
  background-color: #ec643c;
  color: #f9f4ea;
}

.drawer {
  padding: 20px;
  background-color: #1d1d1b;
  color: #f9f4ea;
  height: 100%;
}

.drawerCloseButton {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.MuiModal-backdrop {
  background-color: rgba(71, 71, 71, 0.53) !important;
}

.MuiDrawer-paper {
  background-color: transparent !important;
  /* width: 65% !important; */
}

.list-item-container {
  display: flex;
  justify-content: center;
}
