.hamburger-menu {
  z-index: 1;
  cursor: pointer;

 
}

.menu-bar1,
.menu-bar2,
.menu-bar3 {
  width: 22px;
  height: 3px;
  background-color: #FDCF72;
}

.menu-bar2 {
  width: 14px;
  margin-right: auto;
  margin-top: 5px;
  margin-bottom: 5px;
}
